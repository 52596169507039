import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import RestApi from "../api/RestApi";

export const getRechargeSettings = createAsyncThunk(
  "user/getRechargeSettings ",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await RestApi.get(`/admin/getRechargeSettings`, config);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const listuserTxnId = createAsyncThunk(
  "user/listuserTxnId ",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await RestApi.get(`/app/listuserTxnId`, config);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);
// Async thunk for create recharge
export const rechargePhone = createAsyncThunk(
  "user/rechargePhone",
  async (formData, { getState, rejectWithValue, dispatch }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      dispatch(clearError());
      const { data } = await RestApi.post("/app/recharge", formData, config);
      return data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      }
      return rejectWithValue(error.message);
    }
  }
);

// async thunk for recharge status
export const rechargeStatus = createAsyncThunk(
  "user/rechargeStatus",
  async (formData, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      // Make request to the backend
      const { data } = await RestApi.post(
        "/app/rechargeStatus",
        formData,
        config
      );
      return data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const onlineTransaction = createAsyncThunk(
  "user/onlineTransaction",
  async (formData, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      // Make request to the backend
      const { data } = await RestApi.post(
        "/app/createUserOnlineTxn",
        formData,
        config
      );
      return data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      }
      return rejectWithValue(error.message);
    }
  }
);

//recharge slice with initial state
const rechargeSlice = createSlice({
  name: "recharge",
  initialState: {
    loading: false,
    rechargeTxn: null,
    settings: null,
    error: null,
    success: null,
  },
  reducers: {
    clearError: (state) => {
      state.loading = false;
      state.success = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRechargeSettings.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(getRechargeSettings.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.settings = payload;
      })
      .addCase(getRechargeSettings.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(listuserTxnId.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(listuserTxnId.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.rechargeTxn = payload;
      })
      .addCase(listuserTxnId.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      // to make recharge
      .addCase(rechargePhone.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(rechargePhone.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = payload;
      })
      .addCase(rechargePhone.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      // to check the status
      .addCase(rechargeStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(rechargeStatus.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.settings = payload;
      })
      .addCase(rechargeStatus.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(onlineTransaction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(onlineTransaction.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.settings = payload;
      })
      .addCase(onlineTransaction.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

// Export actions
export const { clearError } = rechargeSlice.actions;
export default rechargeSlice.reducer;
