import { Home, Person } from '@mui/icons-material';
import { lazy } from 'react';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
// pages
const Dashboard = lazy(() => import('../pages/Dashboard'));
const Profile = lazy(() => import('../pages/Profile'));
const UplineUsers = lazy(() => import('../pages/UplineUsers'));
const DownlineUsers = lazy(() => import('../pages/DownlineUsers'));
const Payouts = lazy(() => import('../pages/Payouts'));
const Funds = lazy(() => import('../pages/Funds'));
const Offline = lazy(() => import('../pages/Offline'));
const Plans = lazy(() => import('../pages/Plans'));
const UserPlans = lazy(() => import('../pages/UserPlans'));
// const OnlineTxn = lazy(() => import('../pages/OnlineTxn'));
// const BillPay = lazy(() => import("../pages/BillPay"));
export const appRoutes = [
  {
    group: 'Dashboard',
    title: 'Dashboard',
    path: 'dashboard',
    element: <Dashboard />,
    icon: <Home />,
    index: true,
  },
  {
    group: 'Dashboard',
    title: 'Profile',
    path: 'profile',
    element: <Profile />,
    icon: <Person />,
    index: false,
  },
  {
    group: 'Plans',
    title: 'E-Book',
    path: 'ebook-plans',
    element: <Plans />,
    icon: <FactCheckIcon />,
    index: false,
  },
  {
    group: 'Plans',
    title: 'My Plans',
    path: 'my-plans',
    element: <UserPlans />,
    icon: <FactCheckIcon />,
    index: false,
  },
  // {
  //   group: "Plans",
  //   title: "Recharge & Bill Payment",
  //   path: "billpay",
  //   element: <BillPay />,
  //   icon: <FactCheckIcon />,
  // index: false,
  // },
  // {
  //   group: 'Plans',
  //   title: 'Online Transactions',
  //   path: 'Online',
  //   element: <OnlineTxn />,
  //   icon: <LocalAtmIcon />,
  //   index: false,
  // },
  {
    group: 'Users',
    title: 'Direct Users',
    path: 'upline-users',
    element: <UplineUsers />,
    icon: <Person />,
    index: false,
  },
  {
    group: 'Users',
    title: 'Downline Users',
    path: 'downline-users',
    element: <DownlineUsers />,
    icon: <Person />,
    index: false,
  },
  {
    group: 'My Earning Details',
    title: 'Online Earnings ',
    path: 'online-earnings',
    element: <Payouts />,
    icon: <LocalAtmIcon />,
    index: false,
  },
  {
    group: 'Funds',
    title: 'Vendor Transactions',
    path: 'offline',
    element: <Offline />,
    icon: <LocalAtmIcon />,
    index: false,
  },
  {
    group: 'Funds',
    title: 'Fund Transactions',
    path: 'funds',
    element: <Funds />,
    icon: <LocalAtmIcon />,
    index: false,
  },
];
