import { lazy } from 'react';
import { v4 as uuidv } from 'uuid';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { appRoutes } from './routes/config';

import { useScrollToTop } from './hooks/use-scroll-to-top';
// toast
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//app layout
import HomeLayout from './layouts/home';
import DashboardLayout from './layouts/dashboard';
import ProtectedRoute from './routes/ProtectedRoute';
import UnProtectedRoute from './routes/UnProtectedRoute';

const RegisterPage = lazy(() => import('./pages/auth/Register'));
const LoginPage = lazy(() => import('./pages/auth/login'));
const Page404 = lazy(() => import('./pages/page-not-found'));
// home
const Home = lazy(() => import('./pages/eCom/Home'));
const Contact = lazy(() => import('./pages/eCom/Contact'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const TermsConditions = lazy(() => import('./pages/TermsConditions'));
const Profile = lazy(() => import('./pages/Profile'));
const OfflineStore = lazy(() => import('./pages/eCom/OfflineStore'));

const ForgotPassword = lazy(() => import('./pages/auth/ForgotPassword'));
const LiveSessions = lazy(() => import('./pages/eCom/LiveSessions'));
const Recharge = lazy(() => import('./sections/recharges'));
// --------------------------------------------------------

export default function App() {
  useScrollToTop();
  const { userInfo } = useSelector(state => state.auth);

  return (
    <>
      <Routes>
        <Route path="/" element={<HomeLayout />}>
          <Route index element={<Home />} />
          <Route path="OfflineStore" element={<OfflineStore />} />
          <Route path="paybills" element={<Recharge />} />
          <Route path="contact" element={<Contact />} />
          <Route path="privacy_and_policy" element={<PrivacyPolicy />} />
          <Route path="terms_and_conditions" element={<TermsConditions />} />
          <Route path="liveSessions" element={<LiveSessions />} />

          <Route
            path="profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/register"
          element={
            <UnProtectedRoute>
              <RegisterPage />
            </UnProtectedRoute>
          }
        />
        <Route
          path="/register/:referralKey"
          element={
            <UnProtectedRoute>
              <RegisterPage />
            </UnProtectedRoute>
          }
        />

        <Route path="/updatePassword" element={<ForgotPassword />} />

        <Route
          path="/app/*"
          element={
            <ProtectedRoute>
              <DashboardLayout />
            </ProtectedRoute>
          }
        >
          {userInfo !== null
            ? appRoutes?.map(route => (
                <Route
                  index={route.index}
                  key={uuidv()}
                  path={route.path}
                  element={route.element}
                />
              ))
            : null}
          <Route path="billpay/:serviceType" element={<Recharge />} />
        </Route>
        <Route path="*" element={<Page404 />} />
      </Routes>
      {/* tost */}
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
    </>
  );
}
