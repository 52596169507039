import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import RestApi from "../api/RestApi";
export const getAllProducts = createAsyncThunk(
  "products/getAllProducts",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await RestApi.get("/app/list-product");
      return data?.products;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const searchProducts = createAsyncThunk(
  "products/searchProducts",
  async (
    { keyword = "", category, price = [0, 200000] },
    { rejectWithValue }
  ) => {
    try {
      let url = `/app/search-products?keyword=${keyword}&price[gte]=${price[0]}&price[lte]=${price[1]}`;
      if (category) {
        url = `/app/search-products?keyword=${keyword}&category=${category}&price[gte]=${price[0]}&price[lte]=${price[1]}`;
      }
      const { data } = await RestApi.get(url);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getProductDetails = createAsyncThunk(
  "products/getProductDetails",
  async (slug, { rejectWithValue }) => {
    try {
      const { data } = await RestApi.get(`/app/get-product-details/${slug}`);
      return data.product;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

const productsSlice = createSlice({
  name: "products",
  initialState: {
    productList: null,
    filteredPrducts: null,
    productDetails: null,
    status: "idle",
    error: null,
    success: null,
  },
  reducers: {
    clearMessages: (state) => {
      state.success = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllProducts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllProducts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.productList = action.payload;
      })
      .addCase(getAllProducts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(searchProducts.pending, (state) => {
        state.status = "failed";
      })
      .addCase(searchProducts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.filteredPrducts = action.payload;
      })
      .addCase(searchProducts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getProductDetails.pending, (state) => {
        state.status = "failed";
      })
      .addCase(getProductDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.productDetails = action.payload;
      })
      .addCase(getProductDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { clearMessages } = productsSlice.actions;

export default productsSlice.reducer;
